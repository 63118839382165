import _ from 'lodash'
import clickOutside from 'click-outside'

var navEnable = false
window.searchEnable = false

window.emitter.on('DOMContentLoaded', event => {
  document.getElementById('nav-toggler').addEventListener('click', event => {
    event.preventDefault()
    event.stopPropagation()
    let iconOpen = document.querySelector('#nav-toggler .open')
    let iconClose = document.querySelector('#nav-toggler .close')
    navEnable = !navEnable
    if (navEnable) {
      if (!iconOpen.classList.contains('hidden')) {
        iconOpen.classList.add('hidden')
      }
      if (iconClose.classList.contains('hidden')) {
        iconClose.classList.remove('hidden')
      }
    } else {
      enableSearch(false)

      if (iconOpen.classList.contains('hidden')) {
        iconOpen.classList.remove('hidden')
      }
      if (!iconClose.classList.contains('hidden')) {
        iconClose.classList.add('hidden')
      }
    }
    enableNav(navEnable)
  })
  if (document.querySelector('header img.search')) {
    document.querySelector('header img.search').addEventListener('click', function(event) {
      event.preventDefault()
      event.stopPropagation()
      if (window.searchEnable) {
        let img = event.target
        let input = img.previousElementSibling

        if (input.value.trim().length) {
          // Search
        } else {
          enableSearch(false)
        }
      } else {
        enableSearch(true)
      }
    })
  }
  if (document.querySelector('header input.search')) {
    document.querySelector('header input.search').addEventListener("keyup", function(event) {
      event.preventDefault();
      if (event.keyCode === 13) {
        console.log(event.target.value)
        let form = document.getElementById('search-form')
        let input = document.getElementById('search-form-input')
        input.value = event.target.value
        form.submit()
      } else {
        console.log(event.keyCode)
      }
    });
  }
  if (document.querySelector('header .search-pop')) {
    document.querySelector('header .search-pop').addEventListener('click', function(event) {
      event.preventDefault()
      event.stopPropagation()
      let img = event.target
      let wrapper = img.nextElementSibling

      if (window.searchEnable) {
        wrapper.classList.remove('max-h-screen')
        wrapper.classList.add('max-h-0')
        // if (input.value.trim().length) {
        //   // Search
        // } else {
        //   enableSearch(false)
        // }
        window.searchEnable = false
      } else {
        let input = wrapper.querySelector('input')
        // enableSearch(true)
        wrapper.classList.add('max-h-screen')
        wrapper.classList.remove('max-h-0')
        input.focus()
        window.searchEnable = true
      }
    })
  }
  const accordionHeaders = document.querySelectorAll(".accordion-header");
  accordionHeaders.forEach(accordion => {
    if (accordion.classList.contains('active')) {
      const accordionContent = accordion.parentElement.querySelector(".accordion-content");
      accordionContent.classList.add("active");
      accordionContent.classList.remove("inactive");
      accordionContent.style.maxHeight = `${accordionContent.scrollHeight + 32}px`;
      accordion.classList.add("active");
      accordion.classList.remove("inactive");
    }
    accordion.addEventListener("click", function () {
        const accordionContent = accordion.parentElement.querySelector(".accordion-content");
        let accordionMaxHeight = accordionContent.style.maxHeight;

        // Condition handling
        if (accordionMaxHeight == "0px" || accordionMaxHeight.length == 0) {
          accordionContent.classList.add("active");
          accordionContent.classList.remove("inactive");
          accordionContent.style.maxHeight = `${accordionContent.scrollHeight + 32}px`;
          accordion.classList.add("active");
          accordion.classList.remove("inactive");
        } else {
          accordionContent.classList.remove("active");
          accordionContent.classList.add("inactive");
          accordionContent.style.maxHeight = `0px`;
          accordion.classList.remove("active");
          accordion.classList.add("inactive");
        }
    });
  });


  let article_tags = document.querySelectorAll('header .tag')
  for (let i = 0; i < article_tags.length; i++) {
    article_tags[i].addEventListener('click', function(event) {
      if ((event.target.getAttribute('data-href') && !event.target.getAttribute('data-href').includes(window.location.href)) || !window.location.href.includes('eyecare')) {
        window.location.href = event.target.getAttribute('data-href')
        return
      }
      let id = event.target.id
      const element = document.querySelector(`#${id.replace('tag', 'article')}`)
      const topPos = element.getBoundingClientRect().top + window.pageYOffset - document.getElementsByTagName('header')[0].offsetHeight

      window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth' // smooth scroll
      })
    })
  }
})

window.emitter.on('resize', (event, config) => {
  let sizes = {}
  _.keys(_.get(config, 'theme.screens')).forEach(key => {
    sizes[key] = window.innerWidth <= parseInt(_.get(config, `theme.screens.${key}`, '').replace('px', ''))
  })

  window.emitter.emit('tailwindResize', event, sizes)
})

window.emitter.on('tailwindResize', (event, sizes) => {
  if (sizes.lg) {
    // 
  } else {
    enableSearch(false)
    enableNav(false)
  }
})

var unbind = clickOutside(document.getElementById('nav-menu'), function (e) {
  console.log('"mobile search" was *NOT* clicked');
});

if (document.querySelector('header .search-pop')) {
  var unbind = clickOutside(document.querySelector('header .search-pop').parentNode, function (e) {
    console.log('"desktop search" was *NOT* clicked');
  });
}

function enableNav(enable = true) {
  let navMenu = document.getElementById('nav-menu')
  let header = document.getElementsByTagName('header')[0]
  if (enable) {
    if (!navMenu.classList.contains('max-h-[calc(100%-72px)]')) {
      navMenu.classList.add('max-h-[calc(100%-72px)]')
    }
    if (navMenu.classList.contains('max-h-0')) {
      navMenu.classList.remove('max-h-0')
    }
    if (!header.classList.contains('overflow-y-scroll')) {
      header.classList.add('overflow-y-scroll')
    }
    if (header.classList.contains('overflow-visible')) {
      header.classList.remove('overflow-visible')
    }
    if (header.classList.contains('sticky')) {
      header.classList.remove('sticky')
    }
    // if (!document.body.classList.contains('overflow-hidden')) {
    //   document.body.classList.add('overflow-hidden')
    // }
    window.scrollTo({
      top: 0, // scroll so that the element is at the top of the view
      behavior: 'instant' // smooth,instant scroll
    })
  } else {
    if (header.classList.contains('overflow-y-scroll')) {
      header.classList.remove('overflow-y-scroll')
      header.classList.add('overflow-visible')
    }
    if (!header.classList.contains('overflow-visible')) {
      header.classList.add('overflow-visible')
    }
    if (!header.classList.contains('sticky')) {
      header.classList.add('sticky')
    }
    // if (document.body.classList.contains('overflow-hidden')) {
    //   document.body.classList.remove('overflow-hidden')
    // }
    if (navMenu.classList.contains('max-h-[calc(100%-72px)]')) {
      navMenu.classList.remove('max-h-[calc(100%-72px)]')
    }
    if (! navMenu.classList.contains('max-h-0')) {
      navMenu.classList.add('max-h-0')
    }
    let open = document.querySelector('#nav-toggler .open')
    if (open.classList.contains('hidden')) {
      open.classList.remove('hidden')
    }
    let close = document.querySelector('#nav-toggler .close')
    if (! close.classList.contains('hidden')) {
      close.classList.add('hidden')
    }
  }
  navEnable = enable
}

window.enableNav = enableNav

function enableSearch(enable = true) {
  let mobile_img = document.querySelector('header .search')
  if (mobile_img) {
    let mobile_input = mobile_img.previousElementSibling
    let mobile_wrapper = mobile_img.parentNode
    if (enable) {
      mobile_wrapper.classList.add('border-white')
      mobile_wrapper.classList.remove('border-transparent')
      // mobile_wrapper.classList.add('pb-px')
      mobile_input.classList.remove('w-0')
      mobile_input.classList.add('w-full')
      mobile_input.focus()
      window.searchEnable = true
    } else {
      mobile_wrapper.classList.add('border-transparent')
      mobile_wrapper.classList.remove('border-white')
      // mobile_wrapper.classList.remove('pb-px')
      mobile_input.classList.remove('w-full')
      mobile_input.classList.add('w-0')
      window.searchEnable = false
    }
  }
}