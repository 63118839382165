import './bootstrap';
import './header.js';

const popupCenter = ({url, title, w, h}) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemWidthZoom = width / window.screen.availWidth;
  const systemHeightZoom = height / window.screen.availHeight;
  const left = (width - w) / 2 / systemWidthZoom + dualScreenLeft
  const top = (height - h) / 2 / systemHeightZoom + dualScreenTop
  const newWindow = window.open(url, title, 
    `
    scrollbars=yes,
    width=${w / systemWidthZoom}, 
    height=${h / systemHeightZoom}, 
    top=${top}, 
    left=${left}
    `
  )

  if (window.focus) newWindow.focus();
}

window.emitter.on('DOMContentLoaded', event => {
  document.getElementById('line').addEventListener('click', event => {
    popupCenter({ url:'https://line.me/R/ti/p/@200chibf', title: 'Line', w: 480, h: 520 });
    gtag('event', 'click', { 'name': 'side:line' });
    event.preventDefault()
    event.stopPropagation()
    return false;
  })
})